import requestFeatureToggle from '@/hooks/useFeatureToggle/useFeatureToggle';
import useEnvStore from '@/stores/useEnvStore/useEnvStore';
import { useHasPermission as requestPermission } from 'admin-portal-shared-services';

export interface Props {
  children: React.ReactNode;
  performs?: Array<string>;
  fallback?: React.ReactNode;
  featureToggle?: string;
}

type Return = JSX.Element | null;

const Can = ({ children, performs, featureToggle, fallback = null }: Props): Return => {
  const country = useEnvStore((state) => state.country);
  const hasPermission = performs ? requestPermission(performs) : true;

  const [isFeatureToggleEnabled] = featureToggle
    ? requestFeatureToggle(featureToggle, country)
    : [true];

  if (!hasPermission || !isFeatureToggleEnabled) {
    return fallback as Return;
  }

  return children as Return;
};

export default Can;
