const PERMISSIONS = {
  MOV_BYPASS: 'AdminCustomization.movbypass.Execute',
  MOV_DELETE: 'AdminCustomization.movexclude.Delete',

  MOW_BYPASS: 'AdminCustomization.mowbypass.Execute',
  MOW_DELETE: 'AdminCustomization.mowexclude.Delete',

  ALL_COUNTRIES: 'AdminCustomization.allcountries.Read',
};

export default PERMISSIONS;
