import Can from '@/components/Can/Can';
import LoadingPage from '@/components/LoadingPage/LoadingPage';
import { TOGGLE_LIST } from '@/configs/featureToggles';
import { LIST_ROUTES } from '@/configs/routes';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

const HomePage = lazy(() => import('@/pages/HomePage/HomePage'));
const MOVPage = lazy(() => import('@/pages/MOVPage/MOVPage'));
const MOWPage = lazy(() => import('@/pages/MOWPage/MOWPage'));
const DeliveryFeePage = lazy(() => import('@/pages/DeliveryFeePage/DeliveryFeePage'));

const Router = (): JSX.Element => {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route
          path={LIST_ROUTES.HOME}
          element={
            <Suspense fallback={<LoadingPage />}>
              <HomePage />
            </Suspense>
          }
        />
        <Route
          path={LIST_ROUTES.MOV}
          element={
            <Can featureToggle={TOGGLE_LIST.MOV}>
              <Suspense fallback={<LoadingPage />}>
                <MOVPage />
              </Suspense>
            </Can>
          }
        />
        <Route
          path={LIST_ROUTES.MOW}
          element={
            <Can featureToggle={TOGGLE_LIST.MOW}>
              <Suspense fallback={<LoadingPage />}>
                <MOWPage />
              </Suspense>
            </Can>
          }
        />
        <Route
          path={LIST_ROUTES.DELIVERY_FEE}
          element={
            <Can featureToggle={TOGGLE_LIST.DELIVERY_FEE}>
              <Suspense fallback={<LoadingPage />}>
                <DeliveryFeePage />
              </Suspense>
            </Can>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<LoadingPage />}>
              <HomePage />
            </Suspense>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default Router;
