import { TOGGLE_LIST } from '@/configs/featureToggles';
import { LIST_ROUTES } from '@/configs/routes';
import useFeatureToggle from '@/hooks/useFeatureToggle/useFeatureToggle';
import { CourrierManagement, Home, PaymentMethods, Warehouse } from '@hexa-ui/icons';
import { useSidebar as useSideBarService } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';

export const HomeIcon = (): React.ReactElement => <Home />;
export const MOVIcon = (): React.ReactElement => <PaymentMethods />;
export const DeliveryFeeIcon = (): React.ReactElement => <CourrierManagement />;
export const MOWIcon = (): React.ReactElement => <Warehouse />;

const useSidebar = () => {
  const { formatMessage } = useIntl();
  const [canShowMov] = useFeatureToggle(TOGGLE_LIST.MOV);
  const [canShowMow] = useFeatureToggle(TOGGLE_LIST.MOW);
  const [canShowDeliveryFee] = useFeatureToggle(TOGGLE_LIST.DELIVERY_FEE);

  const items = [
    {
      id: 'home',
      title: formatMessage({ id: 'SIDE_MENU.HOME' }),
      icon: HomeIcon,
      path: LIST_ROUTES.HOME,
      isVisible: true,
    },
    {
      id: 'mov',
      title: formatMessage({ id: 'SIDE_MENU.MOV' }),
      icon: MOVIcon,
      path: LIST_ROUTES.MOV,
      isVisible: canShowMov,
    },
    {
      id: 'mow',
      title: formatMessage({ id: 'SIDE_MENU.MOW' }),
      icon: MOWIcon,
      path: LIST_ROUTES.MOW,
      isVisible: canShowMow,
    },
    {
      id: 'delivery_fee',
      title: formatMessage({ id: 'SIDE_MENU.DELIVERY_FEE' }),
      icon: DeliveryFeeIcon,
      path: LIST_ROUTES.DELIVERY_FEE,
      isVisible: canShowDeliveryFee,
    },
  ];

  return useSideBarService({ items: items.filter((item) => item.isVisible), utils: [] });
};

export default useSidebar;
