import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@hexa-ui/theme';
import Layout from './components/Layout/Layout';
import { getApolloClient } from './graphql/graphql';
import useCreateFeatureToggleService from './hooks/useCreateFeatureToggleService/useCreateFeatureToggleService';
import useLoadEnvs from './hooks/useLoadEnvs/useLoadEnvs';
import { IntlProvider } from './i18n';
import useEnvStore from './stores/useEnvStore/useEnvStore';
import { EnvConfig } from './types/common.types';

export default function App(props: EnvConfig) {
  useCreateFeatureToggleService(props.OPTIMIZELY_KEY);
  const country = useEnvStore((state) => state.country);
  useLoadEnvs(props, country);
  const client = getApolloClient(country);

  return (
    <ApolloProvider client={client}>
      <IntlProvider>
        <ThemeProvider theme="tadaPrimary">
          <Layout />
        </ThemeProvider>
      </IntlProvider>
    </ApolloProvider>
  );
}
