import version from '@/configs/version';
import { Paragraph } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { FooterVersionContainer } from './FooterVersion.styles';

const FooterVersion = (): React.JSX.Element => {
  const { formatMessage } = useIntl();

  const currentYear = new Date().getFullYear();

  return (
    <FooterVersionContainer>
      <Paragraph size="xsmall" weight="semibold">
        <span>
          {formatMessage({ id: 'VERSION.VERSION_APP' })} {version}
        </span>
      </Paragraph>
      <Paragraph size="xsmall" weight="semibold">
        <span>&copy;&nbsp;{currentYear}.</span>
        <span>&nbsp;{formatMessage({ id: 'VERSION.RIGHTS_RESERVED' })}</span>
      </Paragraph>
    </FooterVersionContainer>
  );
};

export default FooterVersion;
