import { FEATURE_TOGGLE_V2_PROJECT_NAME } from '@/configs/featureToggles';
import { useAuthenticationService, useFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import { useEffect, useState } from 'react';

export default function useFeatureToggle(
  featureToggleArg: string,
  country?: string,
): [boolean | undefined, { isLoading: boolean }] {
  const [toggle, setToggle] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const authentication = useAuthenticationService();
  const featureToggleInstance = useFeatureToggleServiceV2(FEATURE_TOGGLE_V2_PROJECT_NAME);

  useEffect(() => {
    async function getFeatureToggle() {
      setIsLoading(true);
      await featureToggleInstance.onReady();

      const isFeatureEnabled: boolean = await featureToggleInstance.isFeatureEnabledAudiences(
        featureToggleArg,
        authentication.getUserId(),
        country ? { country_code: country } : {},
      );

      setToggle(isFeatureEnabled);
      setIsLoading(false);
    }

    getFeatureToggle();
  }, [featureToggleArg, country, authentication, featureToggleInstance]);

  return [toggle, { isLoading }];
}
